.loader-wrapper * {
  margin: 0;
  padding: 0;
}

.loader-wrapper,
.loader-wrapper *::after,
.loader-wrapper *::before {
  box-sizing: border-box;
}

.loader-wrapper {
  position: relative;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  perspective: 500px;
  z-index: 9999;
}

.loader-wrapper .line {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  animation: 2s linear infinite;
}

/* every line's custom style */
.loader-wrapper .line:nth-child(1) {
  border-bottom: 2px solid #AFFF00;
  filter: drop-shadow(0 0 10px #d5ff7a);
  transform: rotateX(35deg) rotateY(-45deg);
  animation-name: one;
}

.loader-wrapper .line:nth-child(2) {
  border-right: 2px solid #00AFFF;
  filter: drop-shadow(0 0 10px #60cdff);
  transform: rotateX(50deg) rotateY(10deg);
  animation-name: two;
}

.loader-wrapper .line:nth-child(3) {
  border-top: 2px solid #FF00AF;
  filter: drop-shadow(0 0 10px #ff60cd);
  transform: rotateX(35deg) rotateY(55deg);
  animation-name: three;
}

/* animations */
@keyframes one {
  to {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes two {
  to {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes three {
  to {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}
